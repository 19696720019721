export default {
  url: "https://jomarwebws.jomarweb.com", //"https://jomarwebws.jomarweb.com", //"https://jomarwebws.jomarweb.com", //"https://jomarwebws.jomarweb.com", //"http://192.168.6.192:3000",
  userInfo: {},
  token: "",
  sucursales: [],
  bodyForFetch: {},
  showSnackbar: false,
  message: "",
  time: 2000,
  color: "#003479",
  progressLogin: false,
  secondColor: "#e70033",
  isAuthorized: false,
  navDrawerOpciones: [
    {
      text: "Conteos Pendientes",
      icon: "mdi-clipboard-list",
      url: "/conteos_pendientes",
    },
    {
      text: "Contador",
      icon: "mdi-counter",
      url: "/contador",
    },
    {
      text: "Surtir",
      icon: "mdi-archive-arrow-down",
      url: "/surtir",
    },
    {
      text: "Surtir Con Folio",
      icon: "mdi-archive-arrow-down",
      url: "/surtirfolio",
    },
    {
      text: "Entarimado",
      icon: "mdi-dolly",
      url: "/entarimado",
    },
    {
      text: "Embarque en Progreso",
      icon: "mdi-human-dolly",
      url: "/embarcando",
    },
    {
      text: "Liberar Embarque",
      icon: "mdi-truck-fast",
      url: "/consultapendiente",
    },
  ],
  colorSnackBar: "",
  navbarToggle: 1,
  navDrawerToggle: false,
  tittle: "",
  bodyForFetch2: {},
  almacenPed: "",
  version: "v.1.1.1",
  folioSeleccionado: "",
  dbSelected: 0,
  hayEmpaquesPendientes: false,
  sucursalesSeleccionadas: "",
};
