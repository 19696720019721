<template>
  <div>
    <v-app-bar app color="#141428" dark dense>
      <v-app-bar-nav-icon
        @click="navDrawerToggle = !navDrawerToggle"
      ></v-app-bar-nav-icon>
      <h3 style="color: white; font-weight: 400">
        {{ tittle }}
      </h3>
      <v-spacer></v-spacer>
      <h3 style="color: white; font-weight: 400">
        {{ dbSelected == 0 ? "Jomar" : "JomarUSA" }}
      </h3>
    </v-app-bar>
    <v-navigation-drawer
      v-model="navDrawerToggle"
      absolute
      temporary
      width="285"
    >
      <div style="padding: 25px 0 25px 0; background: #141428">
        <v-row>
          <v-col cols="12" style="padding: 0; text-align: -webkit-center">
            <v-img
              max-height="160"
              max-width="160"
              src="../assets/logo_new.png"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="padding-bottom: 0; padding-left: 25px">
            <h6 style="color: white; font-weight: 400">
              Jomar Industrias S.A. de C.V.
            </h6>
            <h6 style="color: white; font-weight: 400">
              {{ userInfo.nombre }}
            </h6>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item-group v-model="navbarToggle" color="primary">
          <v-list-item
            v-for="(item, i) in navDrawerOpciones"
            :key="i"
            @click="goRoute(item, i)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="Exit()">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script scoped>
import { mapState } from "vuex";
export default {
  name: "NavbarGlobal",
  computed: {
    ...mapState(["navDrawerOpciones", "tittle", "dbSelected"]),
    navbarToggle: {
      get: function () {
        return this.$store.state.navbarToggle;
      },
      set: function (val) {
        this.$store.commit("setNavbarToggle", val);
      },
    },
    navDrawerToggle: {
      get: function () {
        return this.$store.state.navDrawerToggle;
      },
      set: function (val) {
        this.$store.commit("setNavDrawerToggle", val);
      },
    },
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.commit("setIsAuthorized", val);
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.commit("setUserInfo", val);
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.commit("setToken", val);
      },
    },
    folioSeleccionado: {
      get: function () {
        return this.$store.state.folioSeleccionado;
      },
      set: function (val) {
        this.$store.commit("setFolioSeleccionado", val);
      },
    },
  },
  mounted() {
    this.$root.$on("Exit", () => {
      return this.Exit();
    });
  },
  methods: {
    goRoute(item, i) {
      if (this.isAuthorized) {
        this.navbarToggle = i;
        this.$router.push(item.url);
      } else {
        this.Exit();
      }
    },
    Exit() {
      this.userInfo = {};
      this.token = "";
      this.isAuthorized = false;
      this.navDrawerToggle = false;
      this.folioSeleccionado = "";
      localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
.v-navigation-drawer--temporary {
  z-index: 204;
}
</style>
