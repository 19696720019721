<template>
  <v-app>
    <v-main>
      <navbar-global v-if="isAuthorized"></navbar-global>
      <router-view />
    </v-main>
    <v-snackbar
      v-model="showSnackbar"
      outlined
      :color="secondColor"
      :timeout="time"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="secondColor"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import NavbarGlobal from "@/components/NavbarGlobal";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    NavbarGlobal,
  },
  data: () => ({
    //
  }),

  computed: {
    ...mapState(["color", "secondColor", "message", "time"]),
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.state.isAuthorized = val;
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.state.userInfo = val;
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.state.token = val;
      },
    },
    showSnackbar: {
      get: function () {
        return this.$store.state.showSnackbar;
      },
      set: function (val) {
        this.$store.state.showSnackbar = val;
      },
    },
    tittle: {
      get: function () {
        return this.$store.state.tittle;
      },
      set: function (val) {
        this.$store.commit("setTittle", val);
      },
    },
    navbarToggle: {
      get: function () {
        return this.$store.state.navbarToggle;
      },
      set: function (val) {
        this.$store.commit("setNavbarToggle", val);
      },
    },
    dbSelected: {
      get: function () {
        return this.$store.state.dbSelected;
      },
      set: function (val) {
        localStorage.setItem("dbSelected", val);
        this.$store.commit("setdbSelected", val);
      },
    },
  },

  methods: {
    goRoute(ruta) {
      this.$router.push(ruta);
    },
  },

  async mounted() {
    const credenciales = await JSON.parse(localStorage.getItem("credenciales"));
    if (credenciales != null) {
      //await this.$store.commit("setCredenciales", credenciales);
      this.token = credenciales.token;
      this.userInfo = credenciales.userInfo;
      this.isAuthorized = credenciales.isAuthorized;
      this.tittle = credenciales.userInfo.sucursal;
    }
    const navbarTogglesel = localStorage.getItem("navbarToggle");
    if (navbarTogglesel != null) {
      this.navbarToggle = parseInt(navbarTogglesel);
    }
    const dbSelect = localStorage.getItem("dbSelected");
    if (dbSelect != null) {
      this.dbSelected = parseInt(dbSelect);
    }
    if (!this.isAuthorized && this.$route.path != "/login") {
      this.$store.commit("ExitApp");
    }
  },

  async updated() {
    const credenciales = await JSON.parse(localStorage.getItem("credenciales"));
    if (credenciales != null) {
      this.token = credenciales.token;
      this.userInfo = credenciales.userInfo;
      this.isAuthorized = credenciales.isAuthorized;
      this.tittle = credenciales.userInfo.sucursal;
    }
    const navbarTogglesel = await localStorage.getItem("navbarToggle");
    if (navbarTogglesel != null) {
      this.navbarToggle = parseInt(navbarTogglesel);
    }
    const dbSelect = localStorage.getItem("dbSelected");
    if (dbSelect != null) {
      this.dbSelected = parseInt(dbSelect);
    }
    if (!this.isAuthorized && this.$route.path != "/login") {
      this.$store.commit("ExitApp");
    }
  },
};
</script>

<style>
@media print {
  .noPrint {
    display: none;
  }
  body {
    zoom: 80%;
  }
}
</style>
